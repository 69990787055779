import { mapNumberDto } from '~/shared/dtoMappers';

import { PortfolioAsset, PortfolioAssetDTO, PremadePortfolio, PremadePortfolioDto } from '../premade.portfolio.types';

const mapAssetType = (dto: PortfolioAssetDTO['type']): PortfolioAsset['type'] => {
  switch (dto) {
    case 'STOCK':
      return 'stock';
    case 'BOND':
      return 'bond';
    case 'ETF':
      return 'ETF';
  }
};

const mapItemsDto = (dto: PremadePortfolioDto['proposed_assets']): PremadePortfolio['items'] => {
  const baseResults = dto.map((assetItem) => ({
    ratio: mapNumberDto(assetItem.ratio),
    asset: {
      title: assetItem.asset.name,
      type: mapAssetType(assetItem.asset.type),
      ticker: assetItem.asset.ticker,
    },
  }));

  const totalWeight = baseResults.reduce((acc, { ratio }) => {
    return acc + ratio;
  }, 0);

  return baseResults.map((item) => ({ ...item, ratio: (item.ratio / totalWeight) * 100 }));
};

export const mapPremadePortfolioDtoToDomain = (dto: PremadePortfolioDto[]): PremadePortfolio[] =>
  dto.map((portfolio) => ({
    id: portfolio.id,
    name: portfolio.name,
    description: portfolio.detailed_description,
    shortDescription: portfolio.description,
    icon: portfolio.icon,
    riskLevel: portfolio.risk_level,
    items: mapItemsDto(portfolio.proposed_assets).sort(({ ratio: aRatio }, { ratio: bRatio }) =>
      aRatio > bRatio ? -1 : 1,
    ),
  }));
