import * as RD from '@devexperts/remote-data-ts';

import { mapApiError } from '@models/ApiError';
import { privateAPIv2 } from '~/api/base';
import { response } from '@api/helpers';

import { PopupResult } from '../Popups.types';
import { mapPopupsDtoToDomain } from '../helpers/mapPopupsDtoToDomain.mapper';

export const popupsApi = async () =>
  response(privateAPIv2.get(`/popups/`))
    .then(mapPopupsDtoToDomain)
    .then(RD.success)
    .catch((e): PopupResult => RD.failure(mapApiError(e)));
