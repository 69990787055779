import * as RD from '@devexperts/remote-data-ts';

import { ApiError, mapApiError } from '@models/ApiError';
import { privateAPIv2 } from '~/api/base';
import { response } from '@api/helpers';

export type NpsSurveyResult = RD.RemoteData<ApiError, null>;

export type NpsSurveyParams = {
  score: number | null;
};

export const surveyApi = async (params: NpsSurveyParams) =>
  response(privateAPIv2.post(`/nps/`, { score: params.score }))
    .then((): NpsSurveyResult => RD.success(null))
    .catch((e): NpsSurveyResult => RD.failure(mapApiError(e)));
