import { call, put } from 'redux-saga/effects';

import profileApi from '@api/endpoints/profile';
import { responseCallback } from '@api/helpers';

import { Profile } from '@models/profile';

import * as actions from '@store/profile/actions';
import * as profileLocals from '@store/profile/local';

import { persist, remove } from '@utils/persistence';

export function* loadProfile() {
  try {
    yield put(actions.fetchProfileStarted());
    const profile: Profile = yield call(responseCallback(profileApi.retrieve));

    persist(profileLocals.HAS_ALPACA_ACCOUNT_FIELD, profile.has_alpaca_account || false);
    persist(
      profileLocals.ACCOUNT_CLOSURE_FIELD,
      profile.closure_initiated || profile.closure || profile.rejected || false,
    );
    yield put(actions.fetchProfileSucceeded(profile));
  } catch (e) {
    remove(profileLocals.HAS_ALPACA_ACCOUNT_FIELD);
    remove(profileLocals.ACCOUNT_CLOSURE_FIELD);
    yield put(actions.fetchProfileFailed('Failed to load profile'));
    throw e;
  }
}
