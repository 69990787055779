import * as RD from '@devexperts/remote-data-ts';
import { ApiError } from '@models/ApiError';

export enum PopupsNames {
  SURVEY = 'survey',
}

export type PopupsDTO = Array<{
  name: string;
}>;

export type Popups = Array<{
  name: PopupsNames;
}>;

export type PopupResult = RD.RemoteData<ApiError, Popups>;
