import { Popups, PopupsDTO, PopupsNames } from '../Popups.types';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';

const mapPopupNameDtoToDomain = (name: string): PopupsNames | null => {
  switch (name) {
    case 'survey':
      return PopupsNames.SURVEY;
    default:
      return null;
  }
};

export const mapPopupsDtoToDomain = (dto: PopupsDTO): Popups =>
  pipe(
    dto,
    A.filterMap((item) => {
      const key = mapPopupNameDtoToDomain(item.name);
      return key ? O.some({ name: key }) : O.none;
    }),
  );
