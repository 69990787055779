import { useMetaNotification } from '~/shared/hooks/useMetaNotification';
import { NotificationType } from '~/contexts/notifications';

const KEY = 'emailUpdated';

export const useEmailNotifications = () => {
  useMetaNotification({
    message: 'Your email was successfully updated',
    placement: 'bottomRight',
    type: NotificationType.SUCCESS,
    name: KEY,
    duration: 10,
  });
};
