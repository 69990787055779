import { PortfolioListItem } from '../portfolio.types';

export const getPortfolioAvailableCashBalance = (portfolioItem: PortfolioListItem) =>
  portfolioItem.cash - portfolioItem.reservedCash;

export const getPortfolioWalletBalance = (portfolioItem: PortfolioListItem) => portfolioItem.cash;

export const getPortfolioAvailableBalance = (portfolioItem: PortfolioListItem) => portfolioItem.equity;

export const getTotalPortfolioHoldingsBalance = (portfolioItem: PortfolioListItem) =>
  portfolioItem.equity - portfolioItem.cash;

export const isEmptyBalancePortfolio = (portfolioItem: PortfolioListItem) =>
  portfolioItem.equity === 0 && portfolioItem.cash === 0 && portfolioItem.reservedCash === 0;
