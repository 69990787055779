import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';
import * as RD from '@devexperts/remote-data-ts';

import { setHoldingsAction } from '../../store';
import { HoldingEntity } from '../../Holding.entity.types';
import { usePortfolioHoldings } from '../usePortfolioHoldings';

export const useChangeLocalPortfolioHoldingsValue = () => {
  const dispatch = useDispatch();
  const [holdings] = usePortfolioHoldings();

  return useCallback(
    (
      holdingTicker: string,
      getNextStateOrPartialNextState: Partial<HoldingEntity> | ((previousState: HoldingEntity[]) => HoldingEntity[]),
    ) => {
      if (holdingTicker) {
        pipe(
          holdings,
          RD.map((holdingsData) => {
            if (typeof getNextStateOrPartialNextState === 'function') {
              return getNextStateOrPartialNextState(holdingsData);
            } else {
              return pipe(
                holdingsData,
                A.map((holdingData) => {
                  if (holdingData.ticker === holdingTicker) {
                    return {
                      ...holdingData,
                      ...getNextStateOrPartialNextState,
                    };
                  }
                  return holdingData;
                }),
              );
            }
          }),
          (newHoldingsData) => dispatch(setHoldingsAction(newHoldingsData)),
        );
      }
    },
    [dispatch, holdings],
  );
};
