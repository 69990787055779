import { useEffect, useRef } from 'react';
import { pipe } from 'fp-ts/function';
import * as RD from '@devexperts/remote-data-ts';
import { useSelector } from 'react-redux';

import { GlobalEvent, useDispatchGlobalEvent } from '@utils/globalEvent';
import { useNotification } from '~/shared/hooks/useNotification';
import { NotificationType } from '~/contexts/notifications';

import { selectActivePortfolioInfo } from '../../selectors';
import { portfolioItemEqById } from '../../helpers';

export const usePortfolioChangesEffects = () => {
  const activePortfolioInfo = useSelector(selectActivePortfolioInfo, portfolioItemEqById.equals);
  const isFirstSetNotificationCall = useRef(true);

  const { openNotification } = useNotification();

  const globalEventDispatch = useDispatchGlobalEvent();

  useEffect(() => {
    pipe(
      activePortfolioInfo,
      RD.map(({ id, name }) => {
        if (id) {
          globalEventDispatch(GlobalEvent.HIDE_ALL_POPUPS);
          globalEventDispatch(GlobalEvent.HIDE_SIDE_PAGE);
        }

        if (name) {
          if (!isFirstSetNotificationCall.current) {
            openNotification({
              message: `Switched to ${name} portfolio`,
              placement: 'bottomRight',
              type: NotificationType.SUCCESS,
            });
          } else {
            isFirstSetNotificationCall.current = false;
          }
        }
      }),
    );
  }, [activePortfolioInfo, globalEventDispatch, openNotification]);
};
