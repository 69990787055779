import { PortfolioRiskLevelValue } from '../../portfolio.types';

export const getPremadeRiskLevelDescription = (riskLevel: PortfolioRiskLevelValue) => {
  switch (riskLevel) {
    case 2:
      return {
        title: 'Balanced risk level',
        description: `Aiming for a middle ground between growth and risk,
         with a diversified portfolio across industries, while including bonds to manage volatility.`,
      };
    case 3:
      return {
        title: 'Growth-Oriented risk level',
        description: `Aim for steady growth by accepting moderate risk through a focused portfolio,
         which is combining a mix of stocks and bonds.`,
      };
    default:
      return {
        title: '',
        description: '',
      };
  }
};
