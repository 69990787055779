import * as RD from '@devexperts/remote-data-ts';

import { privateAPIv2 } from '~/api/base';
import { response } from '@api/helpers';
import { mapApiError } from '@models/ApiError';

import { PremadePortfoliosData } from '../premade.portfolio.types';
import { mapPremadePortfolioDtoToDomain } from '../helpers';

export const getPremadePortfolios = async (): Promise<PremadePortfoliosData> => {
  return response(privateAPIv2('/portfolios/pre-made/'))
    .then(mapPremadePortfolioDtoToDomain)
    .then(RD.success)
    .catch((e): PremadePortfoliosData => RD.failure(mapApiError(e)));
};
