import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import * as RD from '@devexperts/remote-data-ts';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';

import { selectPremadePortfolio } from '@store/onboarding/selectors';

import { PremadePortfolio } from '../../preMade';
import { PortfolioTypeValue } from '../../portfolio.types';
import { generateNewPortfolioNameHelper } from '../../helpers';

type Params = {
  name: string | undefined;
  type: PortfolioTypeValue;
  existingNames: string[];
  premadeId?: string;
};

export const useNewPortfolioNameGenerator = () => {
  const premadePortfolios = useSelector(selectPremadePortfolio);

  const getPremadeName = useCallback(
    (id?: string) => {
      return (
        id &&
        pipe(
          premadePortfolios,
          RD.getOrElse((): PremadePortfolio[] => []),
          A.findFirst((premade) => premade.id === id),
          O.fold(
            () => id,
            (item) => item.name,
          ),
        )
      );
    },
    [premadePortfolios],
  );

  return useCallback(
    (params: Params) => {
      const premadeName = getPremadeName(params.premadeId);

      return generateNewPortfolioNameHelper({
        name: params.name,
        type: params.type,
        existingNames: params.existingNames,
        premadeName,
      });
    },
    [getPremadeName],
  );
};
