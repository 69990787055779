import { DefaultRootState } from 'react-redux';
import { createSelector } from 'reselect';

import { OnboardingState } from '@store/onboarding';

const onboardingSelector = (state: DefaultRootState) => state.Onboarding;

export const selectOnboarding = onboardingSelector;

export const selectActiveTopicsIds = createSelector(
  onboardingSelector,
  (Onboarding: OnboardingState) => Onboarding.topics,
);

export const selectOnboardingStep = createSelector(
  onboardingSelector,
  (Onboarding: OnboardingState) => Onboarding.onboardingStep,
);

export const selectPremadePortfolioType = createSelector(
  onboardingSelector,
  (Onboarding: OnboardingState) => Onboarding.premadeType,
);

export const selectPremadePortfolio = createSelector(
  onboardingSelector,
  (Onboarding: OnboardingState) => Onboarding.premade,
);

export const selectPortfolioType = createSelector(
  onboardingSelector,
  (Onboarding: OnboardingState) => Onboarding.portfolioType,
);
