import { capitalizeFirstLetter } from '@utils/strings';

import { PORTFOLIO_TYPE, PortfolioTypeValue } from '../portfolio.types';

export const generateNewPortfolioNameHelper = (data: {
  name: string | undefined;
  type: PortfolioTypeValue;
  existingNames: string[];
  premadeName?: string;
}) => {
  const name = data.name ? `${capitalizeFirstLetter(data.name.toLowerCase())}'s ` : '';

  let mappedPortfolioType: string = '';
  switch (data.type) {
    case PORTFOLIO_TYPE.PRE_MADE:
      mappedPortfolioType = `${name}Pre-Built Portfolio`;
      break;
    case PORTFOLIO_TYPE.MIXED:
      mappedPortfolioType = `${name}Hybrid Portfolio`;
      break;
    case PORTFOLIO_TYPE.SELF_DIRECT:
      mappedPortfolioType = `${name}Self-Directed Portfolio`;
      break;
  }

  if (data.premadeName) {
    mappedPortfolioType = `${mappedPortfolioType} | ${data.premadeName}`;
  }

  if (!data.existingNames.includes(mappedPortfolioType)) {
    return mappedPortfolioType;
  }

  let index = 2;
  while (index < 100) {
    const mappedTypeWithIndex = `${mappedPortfolioType} ${index}`;
    if (!data.existingNames.includes(mappedTypeWithIndex)) {
      return mappedTypeWithIndex;
    }
    index++;
  }

  return mappedPortfolioType;
};
