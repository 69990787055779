import { DefaultRootState } from 'react-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import * as RD from '@devexperts/remote-data-ts';
import { pipe } from 'fp-ts/function';
import { format } from 'date-fns';
import parse from 'date-fns/parse';
import isValid from 'date-fns/isValid';

import { mapApiError } from '@models/ApiError';
import profileApi from '@api/endpoints/profile';
import { response } from '@api/helpers';
import { ProfileSettingsDTO, ProfileSettingsValue } from '@api/schemas/profile';
import * as builder from '@store/builder';

import { profileSettingsSelector } from './profileSettings.selectors';
import { ProfileSettings } from './settings.store.types';

const DATE_FORMAT = 'yyyy-MM-dd';

const action = builder.getModuleAction('PROFILE_SETTINGS');

const FETCH = action('FETCH');
const SET = action('SET');
const MODIFY = action('MODIFY');

const SET_LOADING = action('SET_LOADING');

const initialState: ProfileSettings = {
  isLoading: false,
  data: RD.initial,
};

const ONBOARDINGS_DEFAULT_VALUES = {
  portfolioOnboardingPassed: true,
  preMadePortfolioOnboardingPassed: true,
  selfDirectedPortfolioOnboardingPassed: true,
  portfoliosSwitcherShowCount: 2,
  changeRiskLevelEnabled: false,
};

const setProfileInfo = (payload: ProfileSettings['data']) => ({
  type: SET,
  payload: payload,
});

const setLoading = (isLoading: boolean) => ({
  type: SET_LOADING,
  payload: isLoading,
});

type ProfileSettingsAction = ReturnType<typeof setProfileInfo> | ReturnType<typeof setLoading>;

export const modifyProfileSettingAction = (payload: ProfileSettingsValue) => ({
  type: MODIFY,
  payload,
});

type ModifyAction = ReturnType<typeof modifyProfileSettingAction>;

export const profileSettingsReducer = (state = initialState, action: ProfileSettingsAction): ProfileSettings => {
  switch (action.type) {
    case SET: {
      return {
        ...state,
        data: action.payload,
      } as ProfileSettings;
    }
    case SET_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      } as ProfileSettings;
    }
    default:
      return state;
  }
};

function* loadProfileSettings() {
  yield put(setProfileInfo(RD.pending));

  try {
    const profileSettings: ProfileSettingsDTO = yield call(response, profileApi.getProfileMetaInfo());

    const result: ProfileSettingsValue = {
      portfolioOnboardingPassed: profileSettings.portfolioOnboardingPassed,
      preMadePortfolioOnboardingPassed: profileSettings.preMadePortfolioOnboardingPassed,
      selfDirectedPortfolioOnboardingPassed: profileSettings.selfDirectedPortfolioOnboardingPassed,
      portfoliosSwitcherShowCount: profileSettings.portfoliosSwitcherShowCount || 0,
      dontShowInitialDisclaimer: profileSettings.dontShowInitialDisclaimer,
      changeRiskLevelEnabled: profileSettings.changeRiskLevelEnabled || false,
      hideInitialDisclaimerDate: profileSettings.hideInitialDisclaimerDate
        ? new Date(profileSettings.hideInitialDisclaimerDate)
        : undefined,
      lastActivePortfolioId: profileSettings.lastActivePortfolioId,
      notifications: profileSettings.notifications,
    };

    if (profileSettings.hideInitialDisclaimerDate) {
      const date = parse(profileSettings.hideInitialDisclaimerDate, DATE_FORMAT, new Date());
      if (isValid(date)) {
        result.hideInitialDisclaimerDate = date;
      }
    }

    yield put(setProfileInfo(profileSettings ? RD.success(result) : RD.success({})));
  } catch (e) {
    yield put(setProfileInfo(RD.failure(mapApiError(e))));
  }
}
export function* watchLoadProfileSettings() {
  yield takeLatest(FETCH, loadProfileSettings);
}
export const loadProfileSettingAction = builder.buildRequestAction(FETCH);

function* modifyProfileSettings(action: ModifyAction) {
  yield put(setLoading(true));
  try {
    const state: DefaultRootState = yield select();
    const settingsState = profileSettingsSelector(state);

    const { hideInitialDisclaimerDate: _, ...spreadedRequestData } = action.payload;
    const requestData: ProfileSettingsDTO = spreadedRequestData;

    if (action.payload.hideInitialDisclaimerDate) {
      requestData.hideInitialDisclaimerDate = format(action.payload.hideInitialDisclaimerDate, DATE_FORMAT);
    }

    const apiResponse: ProfileSettingsValue = yield call(response, profileApi.setProfileMetaInfo(requestData));

    const result = pipe(
      settingsState,
      RD.map((data) => {
        return {
          ...data,
          ...apiResponse,
        };
      }),
      RD.alt(() =>
        RD.success({
          ...ONBOARDINGS_DEFAULT_VALUES,
          dontShowInitialDisclaimer: false,
        } as ProfileSettingsValue),
      ),
    );
    yield put(setProfileInfo(result));
  } catch (_) {
    const state: DefaultRootState = yield select();
    const settingsState = profileSettingsSelector(state);
    const result = pipe(
      settingsState,
      RD.map((data) => ({
        ...data,
        ...ONBOARDINGS_DEFAULT_VALUES,
      })),
      RD.alt(() =>
        RD.success({
          ...ONBOARDINGS_DEFAULT_VALUES,
        }),
      ),
    );
    yield put(setProfileInfo(result));
  }
  yield put(setLoading(false));
}

export function* watchModifyProfileSettings() {
  yield takeLatest(MODIFY, modifyProfileSettings);
}
