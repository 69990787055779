import { useCallback, useState } from 'react';
import * as RD from '@devexperts/remote-data-ts';

import { PremadePortfoliosData } from '../../premade.portfolio.types';
import { getPremadePortfolios } from '../../api';

export const usePremadePortfoliosHook = (onSuccess?: (premade: PremadePortfoliosData) => void) => {
  const [data, setData] = useState<PremadePortfoliosData>(RD.initial);

  const loadData = useCallback(() => {
    setData(RD.pending);
    if (onSuccess) {
      onSuccess(RD.pending);
    }
    getPremadePortfolios()
      .then((data) => {
        if (data && onSuccess) {
          onSuccess(data);
        }
        return data;
      })
      .then(setData);
  }, [onSuccess]);

  return [data, loadData] as const;
};
