import { useCallback, useState } from 'react';
import * as RD from '@devexperts/remote-data-ts';

import { PopupResult } from '../Popups.types';
import { popupsApi } from '../api';

export const usePopups = () => {
  const [data, setData] = useState<PopupResult>(RD.initial);

  const getData = useCallback(async () => {
    setData(RD.initial);

    popupsApi().then(setData);
  }, []);

  return [data, getData] as const;
};
