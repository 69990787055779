import { useCallback, useState } from 'react';
import * as RD from '@devexperts/remote-data-ts';
import { ApiError } from '@models/ApiError';

import { surveyApi } from '../api';

export const useSurvey = () => {
  const [data, setData] = useState<RD.RemoteData<ApiError, null>>(RD.initial);

  const setScore = useCallback((score: number | null) => {
    setData(RD.pending);
    surveyApi({ score }).then(setData);
  }, []);

  return [data, setScore] as const;
};
